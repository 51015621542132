import moment from "moment";
import { DateTime } from "luxon";

export const getDateFormatObject = (date) => {
  return DateTime.fromFormat(date, "dd-MM-yyyy");
}

export const getMomentDateFormatObject = (date) => {
  return moment(date, "DD-MMM-YYYY");
}

export const getMomentDateObject = (date) => {
  return moment(date);
}

export const getMomentFormatDate = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};


export const getMomentNowObject = () => {
  return moment(new Date());
}

export const getNowFormatDate = (format) => {
  return DateTime.now().toFormat(format || "dd-MMM-yyyy");
}

export const formatDate = (date, format) => {
  return DateTime.fromISO(date).toFormat(format || "dd-MMM-yyyy");
}

export const formatDateTime = (dateTime, format) => {
  return DateTime.fromISO(dateTime).toUTC().toFormat(format || "dd-MMM-yyyy, h:mm:ss a");
}


export function formatDateTime2(dateTime, format) {
  return DateTime.fromISO(dateTime).toUTC().toFormat(format || "yyyy-MM-dd HH:mm:ss");

}


export const formatLocalDateTime = (dateTime, format) => {
  return DateTime.fromHTTP(dateTime).toLocal().toFormat(format || "dd-MMM-yyyy, h:mm:ss a");
}
