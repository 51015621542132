import { requestWithToken } from "../../apiConfigs/request";

export const fetchClients = (query="") => {
  return requestWithToken({
    url: `/client${query}`,
    method: 'GET',
  });
}

export const createClient = (data) => {
  return requestWithToken({
    url: `/client`,
    method: 'POST',
    data
  });
}

export const updateClient = (clientId, data) => {
  return requestWithToken({
    url: `/client/${clientId}`,
    method: 'PUT',
    data
  });
}

export const deleteClient = (clientId) => {
  return requestWithToken({
    url: `/client/${clientId}`,
    method: 'DELETE'
  });
}


export const fetchClientProjects = (clientId, query="") => {
  return requestWithToken({
    url: `/client/${clientId}/projects${query}`,
    method: 'GET'
  });
}


export const sendClientPQF = (clientId, projectId, data) => {
  return requestWithToken({
    url: `/client/${clientId}/projects/${projectId}/send_pqf`,
    method: 'POST',
    data
  });
}
