import React from "react";
import { Alert, Layout } from "antd";

import Header from './Header';
import MainBody from "./main";
import SnackBar from "../../elements/SnackBar";
import { useSelector } from "react-redux";

// FR_PD10_UNEXPECTED
const UNEXPECTED_ERROR_MESSAGE = "Error occurred while trying to retrieve data."

const CustomLayout = () => {

  const { isGlobalError } = useSelector((state) => {
    return {
      isGlobalError: !!state?.notifications?.isGlobalError
    }
  });

  return (
    <Layout>
      <Header />
      {
        isGlobalError ?
          <Alert
            message={(<div>{UNEXPECTED_ERROR_MESSAGE} <a href="javascript:void(0)" onClick={() => {
              window.location.reload();
            }}>Click here to refresh.</a></div>)}
            type={"error"}
            showIcon
            className="z-index-3"
          />
        : null
      }
      <MainBody isGlobalError={isGlobalError} />
    </Layout>
  )
}

export default CustomLayout;