import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useSearchParams, Outlet } from "react-router-dom";

import { Layout } from "antd";

import SideNavMenu from "../../pages/ProjectsCurve/SideNavMenu";
import { useDispatch } from "react-redux";
import { setGlobalError } from "../../store/actions/notifications";

const { Content } = Layout;

const MainBody = (props) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const projectId = searchParams.get('projectId');
  const tabName = searchParams.get('tab');

  useEffect(() => {
    dispatch(setGlobalError(false))
  }, [location.pathname])

  return (
    <Layout className={props?.isGlobalError ? "main-body-root-blur" : ''}>
      {
        ((projectId && tabName) || (location.pathname === '/BOQDetails' || location.pathname === '/ProjectInformation')) ?
          <Layout>
            <SideNavMenu tabName={tabName} locationPathName={location.pathname} />
            <Layout className="site-layout p-15 pt-20 pl-0 overflow-unset">
              <Content
                className="site-layout-background menuTabs dc-tab-overflow-y"
                style={{ minHeight: "82vh" }}
              >
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        : 
        <Layout className="layout-style">
          <Content className="dash-layout">
            <Outlet />
          </Content>
        </Layout>
      }

    </Layout>
  )
}

export default MainBody;