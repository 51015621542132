import { requestWithToken } from "../apiConfigs/request";

export const fetchNotifications = () => {
  return requestWithToken({
    url: `/notifications`,
    method: 'GET',
  });
}

export const deleteNotification = (notificationId) => {
  return requestWithToken({
    url: `/notifications/${notificationId}`,
    method: 'PATCH',
  });
}

export const markAsReadNotifications = () => {
  return requestWithToken({
    url: `/notifications`,
    method: 'PATCH',
  });
}