import * as actionTypes from '../../actions/projects/actionTypes';

export const initialState = {
  projects: [],
  businessTypes: [],
  businessTypeLoading: true,
  currencies: [],
  currenciesLoading: true,
  clientData: [],
  clientDataLoading: true,
  divisionData: [],
  divisionDataLoading: true,
  opeatorsData: [],
  opeatorsDataLoading: true,
  designersData: [],
  designersDataLoading: true,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PROJECTS_DATA:
      return {
        ...state,
        projects: action.data,
      };
    case actionTypes.SET_PROJECT_BUSINESS_TYPE_DATA:
      return {
        ...state,
        businessTypes: action.data,
        businessTypeLoading: !!action?.isLoading
      };
    case actionTypes.SET_PROJECT_CURRENCY_DATA:
      return {
        ...state,
        currencies: action.data,
        currenciesLoading: !!action?.isLoading
      };
    case actionTypes.SET_PROJECT_CLIENT_DATA:
      return {
        ...state,
        clientData: action.data,
        clientDataLoading: !!action?.isLoading
      };
    case actionTypes.SET_PROJECT_DIVISION_DATA:
      return {
        ...state,
        divisionData: action.data,
        divisionDataLoading: !!action?.isLoading
      };
    case actionTypes.SET_PROJECT_OPERATOR_DATA:
      return {
        ...state,
        opeatorsData: action.data,
        opeatorsDataLoading: !!action?.isLoading
      };
    case actionTypes.SET_PROJECT_DESIGNER_DATA:
      return {
        ...state,
        designersData: action.data,
        designersDataLoading: !!action?.isLoading
      };
    default:
      return state;
  }
};

export default projectReducer;
