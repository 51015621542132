import { fetchNotifications } from "../../../services/notification.service";
import * as actionTypes from "./actionTypes";

export const getNotifications = () => {
  return dispatch => {
    return fetchNotifications().then(res => {
      dispatch({ type: actionTypes.SET_NOTIFICATIONS_DATA, data: res?.data?.data });
      return res;
    }).catch(err => {
      dispatch({ type: actionTypes.SET_NOTIFICATIONS_DATA, data: [] });
      console.log(err);
      return err;
    });
  }
}

export const setGlobalError = (isError=false) => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_GLOBAL_ERROR, data: isError });
  }
}