import { requestWithToken } from "../../apiConfigs/request";

export const fetchOperators = (query="") => {
  return requestWithToken({
    url: `/operator${query}`,
    method: 'GET',
  });
}

export const createOperator = (data) => {
  return requestWithToken({
    url: `/operator`,
    method: 'POST',
    data
  });
}

export const updateOperator = (operatorId, data) => {
  return requestWithToken({
    url: `/operator/${operatorId}`,
    method: 'PUT',
    data
  });
}

export const deleteOperator = (operatorId) => {
  return requestWithToken({
    url: `/operator/${operatorId}`,
    method: 'DELETE'
  });
}

export const fetchOperatorProjects = (operatorId) => {
  return requestWithToken({
    url: `/operator/${operatorId}/projects`,
    method: 'GET'
  });
}