import { requestWithToken } from "../../apiConfigs/request";

export const fetchDesigners = (query="") => {
  return requestWithToken({
    url: `/designer${query}`,
    method: 'GET',
  });
}

export const createDesigner = (data) => {
  return requestWithToken({
    url: `/designer`,
    method: 'POST',
    data
  });
}

export const updateDesigner = (operatorId, data) => {
  return requestWithToken({
    url: `/designer/${operatorId}`,
    method: 'PUT',
    data
  });
}

export const deleteDesigner = (operatorId) => {
  return requestWithToken({
    url: `/designer/${operatorId}`,
    method: 'DELETE'
  });
}


export const fetchDesignerProjects = (designerId) => {
  return requestWithToken({
    url: `/designer/${designerId}/projects`,
    method: 'GET'
  });
}
