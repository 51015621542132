import { Spin } from "antd";
import ProvisionLoader from '../../assets/images/LogoAnimation.gif';

const CustomLoader = (props) => {
  return (
    <div className='custom-loader-container'>
      <span className='custom-loader'>
        <img src={ProvisionLoader} style={ props?.imgStyle ? props?.imgStyle : {}} alt="Loading..." loading="lazy" />
      </span>
    </div>
  );
};

const CustomSpin = (props) => {
  return (
    <>
      <Spin
        spinning={!!props?.spinning}
        tip={props?.tip ?? "Loading..."}
        // indicator={<CustomLoader imgStyle={props?.imgStyle} />}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        {...props}
      >
        {props?.children}
      </Spin>
    </>
  )
};

export default CustomSpin;