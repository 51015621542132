import * as actionTypes from '../../actions/auth/actionTypes';

export const initialState = {
  accessToken: "",
  refreshToken: "",
  user: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_DATA:
      return {
        ...state,
        accessToken: action.data.tokens.accessToken,
        refreshToken: action.data.tokens.refreshToken
      };
    case actionTypes.SET_USER_INFO: {
      return {
        ...state,
        user: (action?.data ?? {}),
      }
    }
    default:
      return state;
  }
};

export default authReducer;
