import { createMongoAbility } from '@casl/ability';
import { permissionData } from '../constants/permissionData';

class AbilityService {
  constructor() {
    this._ability = new createMongoAbility([]);
    this.existingPermissions = [];
    this.existingModules = [];
  }

  get ability() {
    const clone = Object.create(this._ability);
    clone.can = this._can;
    return clone;
  }

  get moduleRules() {
    // These are constant rules that allow to perform instance checks.
    // We can add multiple rules for a single action (OR)
    // and multiple conditions for a single rule (AND)
    return []
  }

  _can = (permission, subject, field="") => {
    const contextSubject = subject || 'any';
    return this._ability.can(permission, contextSubject, field);
  }

  addGlobalPermissions = (permissions, modules, subject = 'any') => {
    this.existingPermissions = [];
    this.existingModules = [];

    // You can modify global permissions structure here.
    const totalPermissions = [];

    permissions.forEach(permission => {
      return totalPermissions.push(...permission.permissions);
    });
    const rules = totalPermissions.filter((permission) => {
      const name = permission?.subject?.split('.')?.[0];
      const permissionItem = permissionData?.[name];
      return !!permissionItem;
    }).map(permission => {
      const name = permission?.subject?.split('.')?.[0];
      const permissionItem = permissionData?.[name];
      const screenItem = permission?.subject?.split('.')?.[2];
      const availableColumns = permissionItem.rowItems?.[screenItem]?.availableColumns;
      const validFields = Object.keys(availableColumns || {})?.filter((item) => !permission?.fields?.includes(item))?.map(item => item);
      return {
        action: permission?.action || "",
        subject: permission?.subject || subject,
        fields: validFields?.length ? validFields : [""]
      }
    });

    this.existingPermissions = this.existingPermissions.concat(rules);
    this.existingModules = this.existingModules.concat(modules);
    const allRules = this.moduleRules.concat(this.existingPermissions).concat(this.existingModules);
    this._ability.update(allRules);
  }

  clearGlobalPermissions = () => {
    this.existingPermissions = [];
    this.existingModules = [];
    this.addGlobalPermissions([], []);
  }
}

export default new AbilityService();
