export const MenuItems = {
  venderData: [{
    name: "OS&E",
    key: "VendorManagement/osne",
    path: "/VendorData/VendorManagement/osne",
    isDisabled: false
  }, {
    name: "FF&E",
    key: "VendorManagement/ffne",
    path: "/VendorData/VendorManagement/ffne",
    isDisabled: false
  },
  // {
  //   name: "PQQ",
  //   key: "VendorTemplates",
  //   path: "/MasterData/VendorTemplates",
  //   isDisabled: false
  // },
  {
    name: "Enquiries",
    key: "VendorEnquiryListing",
    path: "/VendorData/VendorEnquiryListing",
    isDisabled: false
  }, {
    name: "Allowance Agreements",
    key: "VendorAllowanceAgreements",
    path: "/VendorData/VendorAllowanceAgreements",
    isDisabled: false
  }],
  masterData: [{
    name: "Discipline",
    key: "Discipline",
    path: "/MasterData/Discipline",
    isDisabled: false
  }, {
    name: "Area",
    key: "Area",
    path: "/MasterData/Area",
    isDisabled: false
  },
  {
      name: "Product Catalog",
      key: "ProductCatalog",
      path: "/MasterData/ProductCatalog",
      isDisabled: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
      isRemoved: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
  }, {
      name: "Product Destination",
      key: "ProductDestination",
      path: "/MasterData/ProductDestination",
      isDisabled: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
      isRemoved: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
  },
  {
    name: "Templates",
    key: "BoQTemplate",
    path: "/MasterData/BoQTemplate",
    isDisabled: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
    isRemoved: process.env.REACT_APP_IS_OSNE_ENABLED !== "true",
  },
  {
    name: "Multiplier Rules",
    key: "MultiplierRules",
    path: "/MasterData/MultiplierRules",
    isDisabled: false
  },
  {
    name: "Product Category",
    key: "ProductCategory",
    path: "/MasterData/ProductCategory",
    isDisabled: false
  }, {
    name: "Product Sub Category",
    key: "ProductSubCategory",
    path: "/MasterData/ProductSubCategory",
    isDisabled: false
  }, {
    name: "Finish Category",
    key: "FinishCategory",
    path: "/",
    isDisabled: true
  }, {
    name: "Finish Category Type",
    key: "FinishCategoryType",
    path: "/",
    isDisabled: true
  }, {
    name: "Document Type",
    key: "DocumentType",
    path: "/",
    isDisabled: true
  }, {
    name: "Currency & Tax Types",
    key: "Budget",
    path: "/MasterData/Budget",
    isDisabled: false
  }, {
    name: "Category Markup",
    key: "CategoryMarkup",
    path: "/MasterData/CategoryMarkup",
    isDisabled: false
  }, {
    name: "Formula Builder",
    key: "FormulaBuilder",
    path: "/MasterData/FormulaBuilder",
    isDisabled: false
  },
],
  operationsData: [{
    name: "Enquiries",
    key: "Enquiries",
    path: "/OperationsData/Enquiries",
    isDisabled: false
  }, {
    name: "Users",
    key: "Users",
    path: "/OperationsData/Users",
    isDisabled: false
  }, {
    name: "Clients",
    key: "Clients",
    path: "/OperationsData/Clients",
    isDisabled: false
  }, {
    name: "Projects",
    key: "Projects",
    path: "/OperationsData/Projects",
    isDisabled: false
  }, {
    name: "Operators",
    key: "Operators",
    path: "/OperationsData/Operators",
    isDisabled: false
  }, {
    name: "Designers",
    key: "Designers",
    path: "/OperationsData/Designers",
    isDisabled: false
  }, {
    name: "Divisions",
    key: "Divisions",
    path: "/OperationsData/Divisions",
    isDisabled: false
  },]
}
