import { useEffect } from 'react';
import 'antd/dist/antd.min.css';
import '@grapecity/wijmo.styles/wijmo.css';
import './styles/flexGridCell.scss';
import './styles/default.scss';
import './styles/project.scss';
import './styles/boq.scss';
import './styles/boqPopup.scss';
import './styles/pqf.scss';
import './styles/pqq.scss';
import './styles/login.scss';
import './styles/client.scss';
import './styles/master.scss';
import './styles/vendor.scss';
import './styles/user.scss';
import './styles/dashboard.scss';
import './styles/sidebarCurve.scss';
import './App.css';
import Routes from './routes';
import * as wjcCore from '@grapecity/wijmo';
import zipy from 'zipyai';

wjcCore.setLicenseKey(process.env.REACT_APP_WIJMO_KEY || 'dev.profitechnologies.com|uat.profitechnologies.com,463883143221121#B0BGCmJCLiMjdxIDMyIiOiIXZ6JCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7R4R9pGNPlUOm3SRMZzV5dDUTZTQyh7Y69EU7x4Z6AzdxZUWEJDSUhWM6FzTzhGVvNjSWB7ZURnTZVTdWJHenZWNYpGOa96bP9kWTNWVv5kRWxUa8pHZrBjUv5URadnZT3CZrEjYoVkcH5UNOVGN5glcQNXbrRGanJHWzkXQzRmQFhkMydzM4Bjd4czMRhVQPFlVGlzQ9s4bZxmZSpWaXljMzVXbWhVcRhGUDFmRv8EUUpHOJhVNVdzasdncOFGUNhVa09UNNFnZ6JnRohmMZNVNSVkdtJmW8hXdmJHd4cGV524aTtGTHhHdYhGbN3UcCp7QqhWM5UmSwsGd7InaQlUQvR6Tp9kWxkFMllFe0l4d8REZrJjbGpla9Z7UpZXVz3iQot4bp5mMrolYXp6Kud5VQR7b9UldEZUcnR4bzdTSPRGOBRkbZdXc9JnSo3kRzMHcsJEViojITJCLigjQ5IzNBVENiojIIJCL6QTNwkzM7QTN0IicfJye&Qf35VfikEMyIlI0IyQiwiIu3Waz9WZ4hXRgACdlVGaThXZsZEIv5mapdlI0IiTisHL3JSNJ9UUiojIDJCLi86bpNnblRHeFBCIyV6dllmV4J7bwVmUg2Wbql6ViojIOJyes4nILdDOIJiOiMkIsIibvl6cuVGd8VEIgc7bSlGdsVXTg2Wbql6ViojIOJyes4nI4YkNEJiOiMkIsIibvl6cuVGd8VEIgAVQM3EIg2Wbql6ViojIOJyes4nIzMEMCJiOiMkIsISZy36Qg2Wbql6ViojIOJyes4nIVhzNBJiOiMkIsIibvl6cuVGd8VEIgQnchh6QsFWaj9WYulmRg2Wbql6ViojIOJyebpjIkJHUiwiI9UDN4YDMgEDM9AzMyAjMiojI4J7QiwiIt36YuMXZpd6bs3mboNWZ4lmZvJHcuQXY5xSbvNmLzVWan3Gbv9GajVGdpZ6byBnL6VGZiojIz5GRiwiIDxETgMXZjlmdyV6UgkncvNXa6RWQg86bpNXa63mcQJiOiEmTDJCLiEjMxEjMyMDNxMDO8MjN4IiOiQWSiwSfdolTi');

function App() {

  useEffect(() => {
    const isRecordingEnabled = Number(process.env.REACT_APP_QNA_RECORD);
    const siteId = process.env.REACT_APP_ZIPY_ID;
    if (isRecordingEnabled && siteId) {
      zipy.init(siteId);
    }
  }, []);

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
