import * as actionTypes from '../../actions/notifications/actionTypes';

export const initialState = {
  notifications: [],
  isNotificationLoading: true,
  isGlobalError: false
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NOTIFICATIONS_DATA:
      return {
        ...state,
        notifications: action.data,
        isNotificationLoading: false
      };
    case actionTypes.SET_GLOBAL_ERROR:
      return {
        ...state,
        isGlobalError: action.data
      };
    default:
      return state;
  }
};

export default notificationReducer;
