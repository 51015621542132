import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { MsalProvider } from "@azure/msal-react";
import { Configuration,  PublicClientApplication } from "@azure/msal-browser";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { store } from './store';

let ErrorBoundaryComponent = ({ children }) => <>{children}</>;

if (process.env.REACT_APP_QNA_RECORD
  && process.env.REACT_APP_BUGSNAG_API_KEY
  && process.env.REACT_APP_NODE_ENV) {
  Bugsnag.start({
    appType: 'client',
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['staging'],
    releaseStage: process.env.REACT_APP_NODE_ENV
  })
  const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
  ErrorBoundaryComponent = ({ children }) => <ErrorBoundary>{children}</ErrorBoundary>;
}

const msalConfiguration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    scopes: [`api://${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/User.Read`],
    redirectUri: '/login',
    postLogoutRedirectUri: '/login',
  }
};


const pca = new PublicClientApplication(msalConfiguration);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundaryComponent>
    <Provider store={store}>
      <BrowserRouter>
        <HelmetProvider>
        <MsalProvider instance={pca}>
          <App />
          </MsalProvider>
        </HelmetProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundaryComponent>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
