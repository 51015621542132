import React from 'react';
import { Alert } from 'antd';

const SnackBar = (props) => {
    const { alertVisible, message, type, closable } = props;
    const messageIsArray = Array.isArray(message);
    return(
    <div>
        {alertVisible ? (
          messageIsArray ?
          message?.map(item => {
            return (
              <Alert
                message={item}
                type={type}
                showIcon
                className="z-index-3 mb-5"
                closable={closable}
                onClose={props?.onClose}
              />
            )
          })
          :
          <Alert
            message={message?.split("\n")?.filter((value)=>!!value?.trim()).map((value)=>{
                return (<div>{value}</div>)
            })}
            type={type}
            showIcon
            className="z-index-3"
            closable={closable}
            onClose={props?.onClose}
          />
        ) : null}
    </div>
    )
}

export default SnackBar;
