import { requestWithToken, simpleRequest } from "../apiConfigs/request";

export const fetchCurrency = (query="") => {
  return requestWithToken({
    url: `/currency${query}`,
    method: 'GET',
  });
}

export const fetchPublicCurrency = () => {
  return simpleRequest({
    url: `/static/currencies`,
    method: 'GET',
  });
}

export const manageCurrency = (data) => {
  return requestWithToken({
    url: `/currency/bulk`,
    method: 'POST',
    data
  });
}

export const fetchTaxtype = (query="") => {
  return requestWithToken({
    url: `/tax${query}`,
    method: 'GET',
  });
}

export const manageTaxtype = (data) => {
  return requestWithToken({
    url: `/tax/bulk`,
    method: 'POST',
    data
  });
}
  
const CurrencyAndTaxTypeService = {
  fetchCurrency,
  manageCurrency,
  fetchTaxtype,
  manageTaxtype
}

export default CurrencyAndTaxTypeService;