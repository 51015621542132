import { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CustomLayout from '../molecules/layout';
import Loadable from '../elements/Loadable';
import lazy from './LazyWithRetry';
import { getUserInfo } from '../store/actions/auth';

const LoginPage = Loadable(lazy(() => import('../pages/Login')));
const ForgotPasswordPage = Loadable(lazy(() => import('../pages/ForgotPassword')));
const ChangePasswordPage = Loadable(lazy(() => import('../pages/ChangePassword')));

const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const DisciplinePage = Loadable(lazy(() => import('../pages/MasterData/Discipline')));
const AreaPage = Loadable(lazy(() => import('../pages/MasterData/Area')));
const ProductCategoryPage = Loadable(lazy(() => import('../pages/MasterData/ProductCategory')));
const ProductSubCategoryPage = Loadable(lazy(() => import('../pages/MasterData/ProductSubCategory')));
const CurrencyAndTaxTypePage = Loadable(lazy(() => import('../pages/MasterData/CurrencyAndTaxType')));
const CategoryMarkupPage = Loadable(lazy(() => import('../pages/MasterData/CategoryMarkup')));
const FormulaBuilderPage = Loadable(lazy(() => import('../pages/MasterData/FormulaBuilder')));
const ProductCatalogPage = Loadable(lazy(() => import('../pages/MasterData/ProductCatalog')));
const ProductDestinationPage = Loadable(lazy(() => import('../pages/MasterData/ProductDestination')));
const BoQTemplatePage = Loadable(lazy(() => import('../pages/MasterData/BoQTemplate')));
const MultiplierRulesPage = Loadable(lazy(() => import('../pages/MasterData/MultiplierRules')));

const ClientsPage = Loadable(lazy(() => import('../pages/OperationsData/Clients')));
const OperatorsPage = Loadable(lazy(() => import('../pages/OperationsData/Operators')));
const DesignersPage = Loadable(lazy(() => import('../pages/OperationsData/Designers')));
const DivisionsPage = Loadable(lazy(() => import('../pages/OperationsData/Divisions')));
const UsersPage = Loadable(lazy(() => import('../pages/OperationsData/Users')));
const EnquiriesPage = Loadable(lazy(() => import('../pages/OperationsData/Enquiries')));
const PublicEnquiryForm = Loadable(lazy(() => import('../pages/OperationsData/Enquiries/PublicEnquiryForm')));
const ProjectsPage = Loadable(lazy(() => import('../pages/OperationsData/Projects')));

const AllowanceAgreementPage = Loadable(lazy(() => import('../pages/VendorData/AllowanceAgreement')));
const VendorEnquiriesPage = Loadable(lazy(() => import('../pages/VendorData/Enquiries')));
const VendorPublicEnquiryForm = Loadable(lazy(() => import('../pages/VendorData/Enquiries/PublicEnquiryForm')));
const VendorFFNEPage = Loadable(lazy(() => import('../pages/VendorData/FFNE')));
const VendorOSNEPage = Loadable(lazy(() => import('../pages/VendorData/OSNE')));
const VendorDashboardPage = Loadable(lazy(() => import('../pages/VendorData/VendorDashboard')));
const VendorMasterPQQ = Loadable(lazy(() => import('../pages/VendorData/VendorPQQ')));

const ProjectContent = Loadable(lazy(() => import('../pages/ProjectsCurve/ProjectContent')));
const BOQDetails = Loadable(lazy(() => import('../pages/BoQ/boqDetails')));

const RFPtabs = Loadable(lazy(() => import('../pages/RFP/RFPtabs')));
const AnalysisDashboardManagement = Loadable(lazy(() => import('../pages/Analysis/AnalysisManagement')));
const BudgetDashboardManagement = Loadable(lazy(() => import('../pages/Budget/BudgetTabs')));

const CapexPage = Loadable(lazy(() => import("../pages/Capex")));
const SpecificationRequest = Loadable(lazy(() => import('../pages/Capex/SpecificationRequest')))

const OSNEBoQDetailPage = Loadable(lazy(() => import('../pages/OSNE/BOQ')))
const OSNEBudgetPage = Loadable(lazy(() => import('../pages/OSNE/Budget')))

const VersionsPage = Loadable(lazy(() => import('../pages/Versions')));

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userRole, userAuthenticated } = useSelector((state) => {
    return {
      userRole: state?.auth?.user?.user,
      userAuthenticated: state?.auth?.accessToken
    }
  });

  useEffect(() => {
    if (!userAuthenticated) {
      navigate('/login')
    } else if (!userRole?.role?.roleSlug) {
      // dispatch(getUserInfo())
    }
    return () => {
      let dropdown = document.querySelector('.wj-dropdown-panel');
      if (dropdown) {
        let event = new KeyboardEvent('keydown', {
          key: 'Enter',
          bubbles: true,
          code: 'Enter',
          keyCode: 13,
        });

        // Dispatch the event to cancel the editing.
        document.activeElement.dispatchEvent(event);
        document.activeElement.dispatchEvent(event);
        dropdown?.remove()
      }
    };
  }, [userAuthenticated, navigate])

  useEffect(() => {
    if (userAuthenticated && location.pathname === '/login') {
      navigate("/")
    } else if (userAuthenticated && location.pathname !== '/change-password' && !!userRole?.isTempPassword) {
      navigate("/change-password")
    }
  }, [location.pathname, userRole])

  return (<>{children}</>)
}

const MainRoutes = [
  {
    path: 'login',
    element: <ProtectedRoute><LoginPage /></ProtectedRoute>
  },
  {
    path: 'forgot-password',
    element: <ForgotPasswordPage />
  },
  {
    path: 'enquiry',
    element: <PublicEnquiryForm />
  },
  {
    path: 'VendorEnquiry',
    element: <VendorPublicEnquiryForm />
  },
  {
    path: '/',
    element: <CustomLayout />,
    children: [
      {
        path: '',
        element: <ProtectedRoute><HomePage /></ProtectedRoute>
      },
      {
        path: 'change-password',
        element: <ProtectedRoute><ChangePasswordPage /></ProtectedRoute>
      },
      {
        path: 'versions',
        element: <ProtectedRoute><VersionsPage /></ProtectedRoute>
      },
      {
        path: 'VendorDashboard',
        element: <ProtectedRoute><VendorDashboardPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/Discipline',
        element: <ProtectedRoute><DisciplinePage /></ProtectedRoute>
      },
      {
        path: 'MasterData/Area',
        element: <ProtectedRoute><AreaPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/ProductCategory',
        element: <ProtectedRoute><ProductCategoryPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/ProductSubCategory',
        element: <ProtectedRoute><ProductSubCategoryPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/Budget',
        element: <ProtectedRoute><CurrencyAndTaxTypePage /></ProtectedRoute>
      },
      {
        path: 'MasterData/CategoryMarkup',
        element: <ProtectedRoute><CategoryMarkupPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/FormulaBuilder',
        element: <ProtectedRoute><FormulaBuilderPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/MultiplierRules',
        element: <ProtectedRoute><MultiplierRulesPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/ProductCatalog',
        element: <ProtectedRoute><ProductCatalogPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/ProductDestination',
        element: <ProtectedRoute><ProductDestinationPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/BoQTemplate',
        element: <ProtectedRoute><BoQTemplatePage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Clients',
        element: <ProtectedRoute><ClientsPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Operators',
        element: <ProtectedRoute><OperatorsPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Designers',
        element: <ProtectedRoute><DesignersPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Divisions',
        element: <ProtectedRoute><DivisionsPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Users',
        element: <ProtectedRoute><UsersPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Enquiries',
        element: <ProtectedRoute><EnquiriesPage /></ProtectedRoute>
      },
      {
        path: 'OperationsData/Projects',
        element: <ProtectedRoute><ProjectsPage /></ProtectedRoute>
      },
      {
        path: 'VendorData/VendorAllowanceAgreements',
        element: <ProtectedRoute><AllowanceAgreementPage /></ProtectedRoute>
      },
      {
        path: 'VendorData/VendorEnquiryListing',
        element: <ProtectedRoute><VendorEnquiriesPage /></ProtectedRoute>
      },
      {
        path: 'VendorData/VendorManagement/ffne',
        element: <ProtectedRoute><VendorFFNEPage /></ProtectedRoute>
      },
      {
        path: 'VendorData/VendorManagement/osne',
        element: <ProtectedRoute><VendorOSNEPage /></ProtectedRoute>
      },
      {
        path: 'MasterData/VendorTemplates',
        element: <ProtectedRoute><VendorMasterPQQ /></ProtectedRoute>
      },
      {
        path: 'ProjectInformation',
        element: <ProtectedRoute><ProjectContent /></ProtectedRoute>
      },
      {
        path: 'BOQDetails',
        element: <ProtectedRoute><BOQDetails /></ProtectedRoute>
      },
      {
        path: 'RFPDashboardManagement',
        element: <ProtectedRoute><RFPtabs /></ProtectedRoute>
      },
      {
        path: 'AnalysisDashboardManagement',
        element: <ProtectedRoute><AnalysisDashboardManagement /></ProtectedRoute>
      },
      {
        path: 'BudgetDashboardManagement',
        element: <ProtectedRoute><BudgetDashboardManagement /></ProtectedRoute>
      },
      {
        path: "Capex/:id",
        element: (
          <ProtectedRoute>
            <CapexPage />
          </ProtectedRoute>
        ),
      },
      {
        path: "SpecificationRequest/:id",
        element: (
          <ProtectedRoute>
            <SpecificationRequest />
          </ProtectedRoute>
        ),
      },
      {
        path: "VendorData/VendorAllowanceAgreements",
        element: (
          <ProtectedRoute>
            <AllowanceAgreementPage />
          </ProtectedRoute>
        ),
      },
      {
        path: 'OSNE/BOQDetails',
        element: <ProtectedRoute><OSNEBoQDetailPage /></ProtectedRoute>
      },
      {
        path: 'OSNE/BudgetDashboardManagement',
        element: <ProtectedRoute><OSNEBudgetPage /></ProtectedRoute>
      },
    ]
  }
];

export default MainRoutes;