import axios from "axios";
import Bugsnag from '@bugsnag/js'
import { message } from "antd";
import AuthService from "../services/auth.service";
import { setupRefreshTimer, callRefresh } from '../utils/authFunctions';
import history from '../utils/customHistory';
import { store } from '../store';
import { signOutUser } from "../store/actions/auth";
import { setGlobalError } from "../store/actions/notifications";

let isRefreshing = false;
let failedQueue = [];

export const requestWithToken = (options, contentType = 'application/json') => {
  const header = {
    'Content-Type': contentType,
    "Accept": contentType,
    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
  }

  if (localStorage.getItem("x-internal-testKey")) {
    header["x-internal-testKey"] = localStorage.getItem("x-internal-testKey")
  }


  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: header
  })

  const logout = () => {
    store.dispatch(signOutUser());
  }

  const onSuccess = (response) => {
    return response;
  }

  const onError = async (error) => {
    // Construct a meaningful error message
    let errorMessage = 'Error in API request';
    if (error?.response) {
      errorMessage += `: Received ${error?.response?.status} from ${error?.config?.url}`;
    } else if (error?.request) {
      errorMessage += ': No response was received';
    } else {
      errorMessage += `: ${error?.message}`;
    }

    // Create an Error object
    const errorObject = new Error(errorMessage);

    // Define metadata to send along with the error
    const metadata = {
      requestData: error?.config,
      responseData: error?.response ? {
        status: error?.response?.status,
        headers: error?.response?.headers,
        data: error?.response?.data
      } : null,
    };

    // Notify Bugsnag
    if (process.env.REACT_APP_QNA_RECORD
      && process.env.REACT_APP_BUGSNAG_API_KEY
      && process.env.REACT_APP_NODE_ENV) {
      Bugsnag.notify(errorObject, function (event) {
        event.addMetadata('api', metadata);
      });
    }

    if (error.request) {
      // Handle status code for 400 and 409 to not show global error.
      store.dispatch(setGlobalError(false));
      return Promise.reject(error.request);
    } else if (error?.response || error?.statusCode) {
      if (error?.response?.status === 403 || error?.statusCode === 403) {
        if (options.url.includes('refresh')) {
          logout()
        }
      } else if (error?.response?.status === 401 || error?.statusCode === 401) {
        //If refresh token expires
        logout()
        // if (options.url.includes('refresh') || window.location.pathname.includes('login')) {
        // } else {
        //   //If other api's fails
        //   await callRefresh()
        //   setupRefreshTimer();
        //   requestWithToken(options);
        // }
      } else if (error?.response?.status === 404 || error?.statusCode === 404) {
        history.push('/404')
      }
      store.dispatch(setGlobalError(false));
      return Promise.reject(!!error?.response?.data ? error?.response?.data : error?.response || error?.error || error?.message);
    } else {
      store.dispatch(setGlobalError(true));
      return Promise.reject(error.message);
    }
  }

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    })
    failedQueue = [];
  }

  instance.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({
            resolve,
            reject
          });
        }).then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return axios(originalRequest);
        }).catch(err => {
          return Promise.reject(err);
        });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      return new Promise(function (resolve, reject) {
        AuthService.callRefresh()
          .then((res) => {
            localStorage.setItem('accessToken', res?.data?.data?.tokens?.accessToken);
            localStorage.setItem('refreshToken', res?.data?.data?.tokens?.refreshToken);
            originalRequest.headers['Authorization'] = 'Bearer ' + res?.data?.data?.tokens?.accessToken;
            processQueue(null, res?.data?.data?.tokens?.accessToken);
            resolve(axios(originalRequest));
            return res
          })
          .catch((err) => {
            if (err !== "Refresh token not found") {
              message.error(err?.message || err?.data?.message);
            }
            processQueue(err, null);
            reject(err);
            logout();
          })
          .finally((data) => {
            isRefreshing = false;
            // if (data?.tokens?.access?.token) store.dispatch({type: "SET_AUTH_DATA", data})
          });
      });
    }
    return Promise.reject(error);
  });
  return instance(options).then(onSuccess).catch(onError);
}

export const simpleRequest = (options) => {
  const header = {
    'Content-Type': 'application/json',
    "Accept": "application/json"
  }
  if (localStorage.getItem("x-internal-testKey")) {
    header["x-internal-testKey"] = localStorage.getItem("x-internal-testKey")
  }
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: header
  })
  const onSuccess = (response) => {
    return response;
  }
  const onError = async (error) => {
    if (error.response) {
      return Promise.reject(!!error?.response?.data ? error?.response?.data : error?.response || error?.message);
    } else if (error.request) {
      return Promise.reject(error?.request);
    } else {
      return Promise.reject(error?.message);
    }
  }
  return instance(options).then(onSuccess).catch(onError);
};
