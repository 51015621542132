import axios from 'axios'
import {requestWithToken} from "../apiConfigs/request";

export const fetchSignedUrl = (data) => {
  return requestWithToken({
    url: '/auth/signed_url',
    method: 'POST',
    data
  })
}

export const uploadDocumentToAzure = (url, data, contentType) => {
  return axios({
    method: 'put',
    url: url,
    headers: { 'Content-Type': contentType, 'x-ms-blob-type': 'BlockBlob' },
    data
  });
}
