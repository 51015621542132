import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Spin,
  Divider,
  Empty,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";

import UnsavedModal from "../Modal/UnsavedModal";
import CookieList from "../../constants/cookieList";
import CookieService from "../../services/cookie.service";
import { NOTIFICATION_TYPES } from "../../constants/notificationTypes";
import { getErrorMessage } from "../../utils/globalFunctions";
import { deleteNotification } from "../../services/notification.service";
import { isObject } from "lodash";
import { formatDate } from "../../utils/dateFunctions";
import CustomSpin from "../../elements/CustomLoader";

const NotificationPopUp = (props) => {
  const navigate = useNavigate();

  const [isloading, setloading] = useState(false);
  const [modal, setModal] = useState({ visible: false, record: null });


  const handleDeleteNotification = async (item) => {
    props?.setOpenNotifications(false);
    let value = checkUnsaved(item);
    if (value === true) {
      await deleteNotification(item?.id).then(response => {
        if (response?.data?.statusCode === 200) {
          handleRedirect(item)
          props?.getNotifications();
        } else {
          message.error(response?.data?.message);
        }
      }).catch(error => {
        const errorMessage = getErrorMessage(error);
        message.error(error?.message || errorMessage);
      });
    }
  };

  const handleRedirect = (item) => {
    if (item?.eventType === NOTIFICATION_TYPES.USER_CREATE) {
      navigate('/OperationsData/Users');
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_ENQUIRY_SUBMIT) {
      navigate('/VendorData/VendorEnquiryListing');
    } else if (item?.eventType === NOTIFICATION_TYPES.PROJECT_ENQUIRY_SEND
      || item?.eventType === NOTIFICATION_TYPES.PROJECT_ENQUIRY_SUBMIT) {
      navigate('/OperationsData/Enquiries');
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_PQQ_SEND
      || item?.eventType === NOTIFICATION_TYPES.VENDOR_PQQ_SUBMIT
      || item?.eventType === NOTIFICATION_TYPES.VENDOR_PQQ_REOPEN
      || item?.eventType === NOTIFICATION_TYPES.VENDOR_ALLOWANCE_RESEND) {
      if (props?.userRole === 'admin') {
        if (isObject(item?.extra?.businessUnitType) ? item?.extra?.businessUnitType?.businessUnitType === 'FF&E' : item?.extra?.businessUnitType === 'FF&E') {
          navigate(`/VendorData/VendorManagement/ffne?vendorId=${item?.entityId}&tabKey=3`);
        } else if (isObject(item?.extra?.businessUnitType) ? item?.extra?.businessUnitType?.businessUnitType === 'OS&E' : item?.extra?.businessUnitType === 'OS&E') {
          navigate(`/VendorData/VendorManagement/osne?vendorId=${item?.entityId}&tabKey=3`);
        } else {
          navigate(`/VendorData/VendorManagement/ffne?vendorId=${item?.entityId}&tabKey=3`);
        }
      } else if (props?.userRole === 'vendor') {
        navigate(`/VendorDashboard?tabKey=pqq`);
      }
    } else if (item?.eventType === NOTIFICATION_TYPES.CLIENT_PQF_SUBMITTED) {
      navigate(`/ProjectInformation?projectId=${item?.entityId}&tab=logistics`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_CREATE) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&tab=boqlisting`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_CLIENT_APPROVAL || item?.eventType === NOTIFICATION_TYPES.BOQ_CLIENT_APPROVAL_SEND) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqmgt`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_CLIENT_APPROVED) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqmgt`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_CLIENT_REJECTED) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqmgt`);
    } else if (item?.eventType === NOTIFICATION_TYPES.CLIENT_PROJECT_PQF_SEND || item?.eventType === NOTIFICATION_TYPES.PROJECT_PQF_SEND) {
      navigate(`/ProjectInformation?projectId=${item?.entityId}&tab=logistics`);
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_RFP_ISSUE) {
      navigate(`/RFPDashboardManagement?projectId=${item?.entityId}&tab=issuerfp`);
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_RFP_RECALL) {
      navigate(`/RFPDashboardManagement?projectId=${item?.entityId}&tab=issuerfp`);
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_RFP_SUBMIT) {
      navigate(`/RFPDashboardManagement?projectId=${item?.entityId}&tab=costing&vendorId=${item?.extra?.vendorId}&packageId=${item?.extra?.rfpPackageId}`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BUDGET_PROVISION_APPROVE) {
      navigate(`/BudgetDashboardManagement?projectId=${item?.entityId}&tab=summaryBudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BUDGET_CLIENT_REJECT) {
      navigate(`/BudgetDashboardManagement?projectId=${item?.entityId}&tab=summaryBudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BUDGET_CLIENT_APPROVE) {
      navigate(`/BudgetDashboardManagement?projectId=${item?.entityId}&tab=summaryBudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BUDGET_CLIENT_SEND) {
      navigate(`/BudgetDashboardManagement?projectId=${item?.entityId}&tab=summaryBudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SEND_PRODUCT_CATALOG_FOR_APPROVAL) {
      navigate(`/MasterData/ProductCatalog`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SEND_PRODUCT_DESTINATION_FOR_APPROVAL) {
      navigate(`/MasterData/ProductDestination`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SEND_BOQ_TEMPLATE_FOR_APPROVAL) {
      navigate(`/MasterData/BoQTemplate?tab=BOQ&starType=${item?.extra?.starType}&operatorId=${item?.extra?.operatorId}`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SEND_OSNE_BOQ_BUDGET) {
      navigate(`/OSNE/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqbudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SEND_OSNE_BOQ_BUDGET_CLIENT) {
      navigate(`/OSNE/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqbudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.SUBMIT_OSNE_CLIENT_BUDGET) {
      navigate(`/OSNE/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqbudget`);
    } else if (item?.eventType === NOTIFICATION_TYPES.VENDOR_SELECTION_APPROVED) {
      if (props?.userRole === 'admin') {
        if (isObject(item?.extra?.businessUnitType) ? item?.extra?.businessUnitType?.businessUnitType === 'FF&E' : item?.extra?.businessUnitType === 'FF&E') {
          navigate(`/VendorData/VendorManagement/ffne`);
        } else if (isObject(item?.extra?.businessUnitType) ? item?.extra?.businessUnitType?.businessUnitType === 'OS&E' : item?.extra?.businessUnitType === 'OS&E') {
          navigate(`/VendorData/VendorManagement/osne`);
        } else {
          navigate(`/VendorData/VendorManagement/ffne`);
        }
      } else if (props?.userRole === 'vendor') {
        navigate(`/VendorDashboard`);
      }
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_MANAGER_APPROVED) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqmgt`);
    } else if (item?.eventType === NOTIFICATION_TYPES.BOQ_MANAGER_REJECTED) {
      navigate(`/BOQDetails?projectId=${item?.entityId}&boqId=${item?.extra?.boqId}&tab=boqmgt`);
    }
  };

  const checkUnsaved = (item) => {
    let value = CookieService.get(CookieList.unsavedValue);
    if (value === "true" || value === true) {
      setModal({ ...modal, visible: true, record: item });
      return false;
    } else {
      return true;
    }
  };

  const clearCookies = () => {
    CookieService.remove(CookieList.unsavedValue);
    CookieService.set(CookieList.unsavedValue, false);
    setModal({ ...modal, visible: false });
  }


  const handleNotificationsData = (data) => {
    return (
      <div className="px-15">
        {data &&
          data.map((listItem) => (
            <div
              onClick={() => handleDeleteNotification(listItem)}
              className="cursor-pointer notify-item"
            >
              <Row>
                <Col span={22} className="notify-header-style">
                  <Form.Item
                    label="Notification Type "
                    className="notify-header-style mb-0"
                  >
                    {listItem?.message}
                  </Form.Item>
                </Col>
                <Col span={4}></Col>
              </Row>
              <Row>
                <Col span={12} className="notify-header-style">
                  <Form.Item
                    label="Entity "
                    className="notify-header-style mb-0"
                  >
                    {listItem?.entityName || "-"}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Notification Date "
                    className="notify-date mb-0"
                  >
                    {listItem?.createdAt
                      ? formatDate(listItem?.createdAt)
                      : "-"}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="Business Unit " className="mb-0">
                    {isObject(listItem?.extra?.businessUnitType) ? listItem?.extra?.businessUnitType?.businessUnitType : listItem?.extra?.businessUnitType || "-"}
                  </Form.Item>
                </Col>
                {listItem.entity_type === "BOQ" && (
                  <Col span={12}>
                    <Form.Item
                      label="Reference No. "
                      className="mb-0 pull-right"
                    >
                      {listItem.ref_number ? listItem.ref_number : "-"}
                    </Form.Item>
                  </Col>
                )}
              </Row>
              {listItem.entity_type === "BOQ" && (
                <Row>
                  <Col span={12}>
                    <Form.Item label="Version No. " className="mb-0">
                      {listItem.ver_no ? listItem.ver_no : "-"}
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Divider />
            </div>
          ))}
      </div>
    );
  };

  return (
    <div>
      <CustomSpin spinning={props?.notifications?.isNotificationLoading || isloading} tip="Loading notifications..Please wait..">
        {props?.notifications?.notifications?.length ? (
          <Form>{handleNotificationsData(props?.notifications?.notifications)}</Form>
        ) : (
          <Empty description={"No Notifications"} />
        )}
      </CustomSpin>
      <UnsavedModal
        isModalVisible={modal.visible}
        handleOk={clearCookies}
        handleCancelUnsave={() => setModal({ ...modal, visible: false })}
      />
    </div>
  );
};

export default NotificationPopUp;
