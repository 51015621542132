import React from "react";
import { Button, Row, Col, Tooltip } from "antd";
import ReactModal from "react-modal-resizable-draggable";

const UnsavedModal = (props) => {
  return (
    <ReactModal
      isOpen={props?.isModalVisible}
      initWidth={470}
      initHeight={"auto"}
      disableResize={true}
      className={`${props?.className} profit-modal-position`}
      onRequestClose={() => {}}
      left={"48%"}
      top={"50%"}
      id="unsaved_modal"
    >
      <p className="mt-17" id="unsaved_modal_message">
        You have unsaved changes. Are you sure you want to leave this
        page?
      </p>
      <Row>
        <Col span={24}>
          <div className="float-right">
            <div className="grey-btn-outer mb-0">
              <Tooltip title="Ok">
                <Button className="grey-btn" onClick={props?.handleOk} id="unsaved_modal_ok_button">
                  OK
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button className="grey-btn" onClick={props?.handleCancelUnsave} id="unsaved_modal_ok_cancel">
                  Cancel
                </Button>
              </Tooltip>
            </div>
          </div>
        </Col>
      </Row>
    </ReactModal>
  )
}

export default UnsavedModal;