import { configureStore } from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import reducer from './reducers';

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const store = configureStore({
  reducer: reducer,
  preloadedState: {
    auth: {
      accessToken: localStorage.getItem("accessToken"),
      refreshToken: localStorage.getItem("refreshToken"),
    }
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: [thunk],
});

const persister = 'Free';

export { store, persister };
