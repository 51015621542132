import jwt from 'jwt-decode'
import { message } from "antd";
import AuthService from "../services/auth.service";
import cookieService from "../services/cookie.service";
import history from './customHistory';
import CookieList from "../constants/cookieList";
import { getTimeDifference } from "./dateFunctions";
import { DateTime } from "luxon";

let refreshTimers = [];

export const _setToken = (data) => {
  localStorage.setItem('accessToken', data.accessToken);
  localStorage.setItem('refreshToken', data.refreshToken);
  const token = jwt(data.accessToken);
  localStorage.setItem('expiryToken', token.exp);
}

let refrestTimer;
export const logout = async () => {
  if (refrestTimer) {
    clearInterval(refrestTimer)
    refrestTimer = null;
  }
  await AuthService.logOutUser().then(()=>{
    localStorage.clear();
    sessionStorage.clear();
    cookieService.remove(CookieList.unsavedValue);
    history.push('/login');
    // window.location.reload();
  })
}

export const isUserAuthenticated = () => {
  if (localStorage.getItem('accessToken')) {
    const token = jwt(localStorage.getItem('accessToken'));
    const refreshToken = jwt(localStorage.getItem('refreshToken'));
    return token?.token_type === 'access' && !(new Date(refreshToken.exp * 1000) < new Date())
  }
  return false
}

export const setupRefreshTimer = async () => {
  if (localStorage.getItem('accessToken')) {
    // const expiryToken = localStorage.getItem('expiryToken')

    // const currentTime = DateTime.now();
    // const expiryTime = DateTime.fromSeconds(parseInt(expiryToken, 10));
    // const durationInSeconds = Math.floor(expiryTime.diff(currentTime, 'seconds').toObject().seconds);

    // console.log(durationInSeconds)

    // // if (durationInSeconds > 0) {
    // //   refrestTimer = setInterval(async () => {
    // //     await callRefresh()
    // //   }, (durationInSeconds - 100) * 1000);
    // // } else {
    // //   await callRefresh()
    // // }

    // // Clear any previous timers
    // refreshTimers.forEach(clearTimeout);
    // refreshTimers = [];

    // const scheduleRefresh = (timeInSeconds) => {
    //   const timer = setTimeout(async () => {
    //     await callRefresh();
    //     // Clear future timers if this call is successful
    //     refreshTimers.forEach(timer => clearTimeout(timer));
    //   }, timeInSeconds * 1000);
    //   refreshTimers.push(timer);
    // };

    // // Schedule calls at 27th, 28th, and 29th minute before expiry
    // [27, 28, 29].forEach(minute => {
    //   const timeToRefresh = durationInSeconds - (minute * 60);
    //   if (timeToRefresh > 0) {
    //     scheduleRefresh(timeToRefresh);
    //   }
    // });

    // if (durationInSeconds <= 0) {
    //   await callRefresh();
    // }

  } else {
    logout()
  }
}

export const callRefresh = async _ => {
  const refreshData = await AuthService.callRefresh()?.then(async (response) => {
    if (response.status === 200 || response.status === 201) {
      _setToken(response.data?.data?.tokens)
    } else {
      logout()
    }
    message.success(response?.message || response?.data?.message);
  }).catch((err) => {
    message.error(err?.message || err?.data?.message);
    logout()
  });
  return refreshData
}
