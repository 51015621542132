import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Button, Layout, Menu, Tooltip } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import CookieList from '../../constants/cookieList';
import CookieService from '../../services/cookie.service';

import UnsavedModal from '../../molecules/Modal/UnsavedModal';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SideNavMenu = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let relative_path =
    location.pathname.includes("ProjectInformation")
      ? "/ProjectInformation"
      : location.pathname;

  const [projectRecord, setProjectRecord] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [defaultCapexKey, setDefaulCapexKey] = useState(null);
  const [collapsed, setCollapsed] = useState(false);
  const [proMenuButtonVisible] = useState(true);
  const [openKeys, setOpenKeys] = useState(['sub1', 'sub2', 'ffne', 'osne']);
  const [keys, setKeys] = useState({
    current: null,
    temp: null,
  });
  const [capexRoute, setCapexRoute] = useState('')

  useEffect(() => {
    setKeys({ ...keys, current: relative_path });
    if (props?.location && props?.location?.state) {
      setProjectRecord(location?.state?.projectRecord);
    }
  }, []);

  useEffect(() => {
    setKeys({ ...keys, current: relative_path });
    if (props?.location && props?.location?.state) {
      setProjectRecord(props?.location?.state?.projectRecord);
    }
  }, [props]);

  const { userRole } = useSelector((state) => {
    return {
      userRole: state?.auth?.user?.user?.role?.roleSlug,
    }
  });

  const { projectList, user } = useSelector((state) => {
    return {
      projectList: state?.projects?.projects || [],
      user: state?.auth?.user?.user,
    };
  });

  const record = useMemo(() => {
    return searchParams.get('projectId') ? projectList?.find(projectItem => projectItem?.id === searchParams.get('projectId')) : null
  }, [projectList, searchParams.get('projectId')]);


  const showModal = () => {
    return (
      <UnsavedModal
        className="header-unsaved"
        isModalVisible={modalVisible}
        handleOk={clearCookies}
        handleCancelUnsave={() => setModalVisible(false)}
      />
    );
  }


  const clearCookies = () => {
    CookieService.remove(CookieList.unsavedValue);
    CookieService.set(CookieList.unsavedValue, false);
    CookieService.remove(CookieList.createRequest);
    CookieService.set(CookieList.createRequest, false);
    setModalVisible(false);
    if (!!defaultCapexKey) {
      setCapexRoute(defaultCapexKey);
      setDefaulCapexKey(null);
      navigate(
        `${defaultCapexKey}?projectId=${searchParams?.get(
          "projectId"
        )}&tab=detailvc`
      );
    } else {
      onChangeMenu(keys.temp);
    }
  }

  const handleMenuClick = (key) => {
    let value = CookieService.get(CookieList.unsavedValue);
    let value2 = CookieService.get(CookieList.createRequest);
    if (value === true || value === "true") {
      setModalVisible(true);
      setDefaulCapexKey(key);
    } else if ((value2 === "true" || value2 === true)) {
      setModalVisible(true)
      setDefaulCapexKey(key);
    } else {
      setCapexRoute(key);
      navigate(
        `${key}?projectId=${searchParams?.get(
          "projectId"
        )}&tab=detailvc`
      );
    }
  };

  const onChangeMenu = (e) => {
    let value = CookieService.get(CookieList.unsavedValue);
    let value2 = CookieService.get(CookieList.createRequest);
    if (value === true || value === "true") {
      setModalVisible(true);
      setKeys({ ...keys, temp: e });
    } else if ((value2 === "true" || value2 === true) && !e.includes("Capex")) {
      setModalVisible(true)
      setKeys({ ...keys, temp: e });
    }
    else {
      setKeys({ ...keys, current: e });
      let record = projectRecord;
      switch (e) {
        case "/ProjectInformation":
          navigate(`/ProjectInformation?projectId=${searchParams?.get('projectId')}&tab=logistics`);
          break;
        case "/BOQDetails":
          navigate(`/BOQDetails?projectId=${searchParams?.get('projectId')}&tab=boqlisting`);
          break;
        case "/VendorDashboardManagement":
          break;
        case "/BudgetDashboardManagement":
          navigate(`/BudgetDashboardManagement?projectId=${searchParams?.get('projectId')}&tab=summaryBudget`);
          break;
        case "/RFPDashboardManagement":
          navigate(`/RFPDashboardManagement?projectId=${searchParams?.get('projectId')}&tab=createrfp`);
          break;
        case "/AnalysisDashboardManagement":
          navigate(`/AnalysisDashboardManagement?projectId=${searchParams?.get('projectId')}&tab=detailvc`);
          break;
        case "/OSNE/BOQDetails":
          navigate(`/OSNE/BOQDetails?projectId=${searchParams?.get('projectId')}&tab=boqlisting`);
          break;
        case "/OSNE/BudgetDashboardManagement":
          navigate(`/OSNE/BOQDetails?projectId=${searchParams?.get('projectId')}&tab=budgetsummary`);
          break;
        default:
          break;
      }
    }
  }


  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  }


  useEffect(() => {
    let newKeys;
    if (record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH') {
      newKeys = cloneDeep(['sub1', 'sub2', 'ffne', 'osne'])?.filter((item) => item !== "sub3") || [];
      if (!newKeys.includes("sub4")) {
        newKeys.push("sub4");
      }
    } else if (record?.businessUnitType === 'CAPEX') {
      newKeys = cloneDeep(['sub1', 'sub2', 'ffne', 'osne'])?.filter((item) => item !== "sub4") || [];
      if (!newKeys.includes("sub3")) {
        newKeys.push("sub3");
      }
    }

    setOpenKeys(newKeys);

  }, [record]);




  return (
    <>
      {modalVisible ? showModal() : null}
      <div className="sider-ml-15 mt-15">
        {proMenuButtonVisible ? (
          <Tooltip
            title={collapsed ? "Open Menu" : "Close Menu"}
            placement="right"
          >
            <Button
              type="link"
              className={collapsed ? "" : "ml-15"}
              style={{ width: "unset", height: "unset" }}
              onClick={() => {
                setCollapsed(!collapsed);
              }}
              icon={React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
              )}
            ></Button>
          </Tooltip>
        ) : null}
        <Sider
          trigger={null}
          collapsedWidth={0}
          collapsible
          collapsed={collapsed}
          theme="light"
          width="95"
        >
          <div>
            {
              process.env.REACT_APP_IS_CAPEX_ENABLED === "true" && process.env.REACT_APP_IS_OSNE_ENABLED === "true" ?
                <Menu
                  selectedKeys={['FF&E', 'OS&E', props?.tabName === 'budgetsummary' ? "/OSNE/BudgetDashboardManagement" : keys.current]}
                  mode="inline"
                  className="promenu"
                  onSelect={(e) => onChangeMenu(e.key)}
                  openKeys={openKeys}
                  onOpenChange={onOpenChange}
                  id='sidebar_menu'
                >
                  {
                    userRole === 'admin' || userRole === 'client' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                      <Menu.Item key="/ProjectInformation" style={{ marginTop: '1rem', marginBottom: '1rem' }} className="single-menu" id="property_information">
                        Property Information
                      </Menu.Item>
                      : null
                  }
                  {
                    process.env.REACT_APP_IS_CAPEX_ENABLED === "true" && userRole !== 'vendor' ?
                      <SubMenu
                        key="sub3"
                        title="Capex"
                        style={{ marginBottom: "1rem" }}
                        defaultSelectedKeys={[capexRoute]} onClick={(e) => handleMenuClick(e.key)}
                      >
                        <Menu.Item key="/Capex/9"
                          disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                        >Create</Menu.Item>
                        <Menu.Item key="/Capex/10"
                          disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                        >Pending</Menu.Item>
                        <Menu.Item key="/Capex/11"
                          disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                        >Approved</Menu.Item>
                      </SubMenu>
                    : null
                  }
                  <SubMenu title="Projects" key='sub4'>
                    <SubMenu key="sub1" title="FF&E" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                      {/* <Menu.Item key="sub1" title="FF&E" id="ffne" style={{ marginTop: '1rem', fontSize: '18px', fontWeight: 600 }} disabled={record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'OS&E'}>
                        FF&E
                      </Menu.Item> */}
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/BOQDetails" disabled={record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'OS&E'} id="ffne_boq">BoQ</Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/VendorDashboardManagement" disabled={true} id="ffne_vendors">
                            Vendors
                          </Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'vendor' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/RFPDashboardManagement" disabled={record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'OS&E'} id="ffne_rfp">RFP</Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <>
                            <Menu.Item key="/AnalysisDashboardManagement" disabled={record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'OS&E'} id="ffne_analysis">
                              Analysis
                            </Menu.Item>
                          </>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'client' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <>
                            <Menu.Item key="/BudgetDashboardManagement" disabled={record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'OS&E'} id="ffne_budget">
                              Budget
                            </Menu.Item>
                          </>
                          : null
                      }
                    </SubMenu>
                    {
                      (userRole === 'admin' || userRole === 'client' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator') ?
                      <>
                        <SubMenu key="sub2" title="OS&E" style={{ marginBottom: '1rem' }}>
                          {/* <Menu.Item id="osne" key="sub2" style={{ marginTop: '20px', fontSize: '18px', fontWeight: 600 }} disabled={process.env.REACT_APP_IS_OSNE_ENABLED !== "true" || record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'FF&E'}>
                            OS&E
                          </Menu.Item> */}
                          <Menu.Item id="osne_boq" key="/OSNE/BOQDetails" disabled={process.env.REACT_APP_IS_OSNE_ENABLED !== "true" || record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'FF&E'}>
                            BOQ
                          </Menu.Item>
                          {/* <Menu.Item key="7" disabled={true}>
                            RFP
                          </Menu.Item> */}
                            <Menu.Item id="osne_budget" key="/OSNE/BudgetDashboardManagement" disabled={process.env.REACT_APP_IS_OSNE_ENABLED !== "true" || record?.businessUnitType === 'CAPEX' || record?.businessUnitType === 'FF&E'}>
                              Budget
                            </Menu.Item>
                        </SubMenu>
                        </>
                        : null}
                    {/* <SubMenu title="FF&E" key='ffne'>
                    </SubMenu>
                    <SubMenu title="OS&E" key='osne'>
                    </SubMenu> */}
                  </SubMenu>

                </Menu>
              : <Menu
                selectedKeys={[keys.current]}
                mode="inline"
                className="promenu"
                onSelect={(e) => onChangeMenu(e.key)}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
              >
                {
                  userRole === 'admin' || userRole === 'client' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                    <Menu.Item key="/ProjectInformation" style={{ marginTop: '1rem', marginBottom: '1rem' }} className="single-menu" id="property_information">
                      Property Information
                    </Menu.Item>
                    : null
                }
                {process.env.IS_CAPEX_AVAILABLE && userRole !== 'vendor' ? <SubMenu
                  key="sub3"
                  title="Capex"
                  style={{ marginBottom: "1rem" }}
                  defaultSelectedKeys={[capexRoute]} onClick={(e) => handleMenuClick(e.key)}
                  disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                >
                  <Menu.Item key="/Capex/9"
                    disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH' || user?.role?.roleName === 'Admin'}
                  >Create</Menu.Item>
                  <Menu.Item key="/Capex/10"
                    disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                  >Pending</Menu.Item>
                  <Menu.Item key="/Capex/11"
                    disabled={record?.businessUnitType === 'FF&E' || record?.businessUnitType === 'OS&E' || record?.businessUnitType === 'BOTH'}
                  >Approved</Menu.Item>
                </SubMenu> : null}
                {process.env.IS_CAPEX_AVAILABLE && record?.businessUnitType === 'CAPEX' && userRole !== 'vendor' ?
                  <SubMenu title="Projects">
                  <Menu.Item style={{ width: '100%', margin: '0 0 10px 0' }}>
                    <SubMenu title="FF&E" style={{ marginBottom: '2.3rem', width: '100%' }} disabled={record?.businessUnitType === 'CAPEX'}>
                    </SubMenu>
                  </Menu.Item>
                  <Menu.Item style={{ width: '100%', margin: '0 0 10px 0' }}>
                    <SubMenu title="OS&E" style={{ marginBottom: '2.3rem' }} disabled={record?.businessUnitType === 'CAPEX'}>
                    </SubMenu>
                  </Menu.Item>
                </SubMenu>
                  :
                  <>
                    <SubMenu key="sub1" title="FF&E" style={{ marginTop: '1rem', marginBottom: '1rem' }} disabled={record?.businessUnitType === 'CAPEX'}>
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/BOQDetails">BoQ</Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/VendorDashboardManagement" disabled={true}>
                            Vendors
                          </Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'vendor' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <Menu.Item key="/RFPDashboardManagement">RFP</Menu.Item>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <>
                            <Menu.Item key="/AnalysisDashboardManagement">
                              Analysis
                            </Menu.Item>
                          </>
                          : null
                      }
                      {
                        userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                          <>
                            <Menu.Item key="/BudgetDashboardManagement">
                              Budget
                            </Menu.Item>
                          </>
                          : null
                      }
                    </SubMenu>
                    {
                      userRole === 'admin' || userRole === 'manager' || userRole === 'client' || userRole === 'coordinator' || userRole === 'designer' || userRole === 'operator' ?
                        <SubMenu key="sub2" title="OS&E" style={{ marginBottom: '1rem' }} disabled={record?.businessUnitType === 'CAPEX'}>
                          <Menu.Item key="6" disabled={true}>
                            BOQ
                          </Menu.Item>
                          <Menu.Item key="8" disabled={true}>
                            Budget
                          </Menu.Item>
                        </SubMenu>
                      : null
                    }
                  </>
                }
              </Menu>
            }
          </div>
        </Sider>
      </div>
    </>
  );
};

export default SideNavMenu;