import { cloneDeep } from "lodash";
import AbilityService from "../services/ability.service";
import { fetchSignedUrl, uploadDocumentToAzure } from "../services/uploadfiles.service";

export const getRowNumber = (data, key) => {
  const index = data.findIndex(item => item.key === key);
  return index <= -1 ? 1 : index + 1;
}

export const isHiddenRows = (allData, filteredData, error) => {
  const errorResponse = error?.response ? JSON.parse(error?.response) : "";
  let errors = errorResponse?.error || [];

  let filteredAllData = cloneDeep(allData);

  let isKeyHidden = false;
  errors = errors.map(error => {
    const filteredAllDataIndex = filteredAllData?.findIndex(item => item?.oldKey && item?.key === error?.key);
    const currentItem = cloneDeep(filteredAllData[filteredAllDataIndex]);
    filteredAllData.splice(filteredAllDataIndex, 1);
    const isItemPresent = filteredData?.find(item => item?.oldKey === currentItem?.oldKey);
    if (!isItemPresent) {
      error.key = 'hidden';
      isKeyHidden = true;
    }
    return error
  })

  errors = errors?.map(item => `${item?.key === 'hidden' ? `At Hidden Row (Filtered)` : `At Row ${getRowNumber(filteredData, item?.key)} `}: ${item?.reason}`)?.slice(0, 3)?.join(";\n");
  return {isKeyHidden, errors}
}

export const getErrorMessage = (error, data, allData, isFilterApplied) => {
  const errorResponse = error?.response ? JSON.parse(error?.response) : "";
  if (!data || !data?.length) {
    if (errorResponse?.error?.length) {
      return errorResponse?.error?.map(item => `${item?.reason}`)?.join("\n");
    }
    return errorResponse?.message;
  }
  if (isFilterApplied) {
    const {isKeyHidden, errors} = isHiddenRows(allData, data, error);
    if (isKeyHidden) {
      return errors;
    }
  }
  return errorResponse?.error?.map(item => `At Row ${getRowNumber(data, item?.key)} : ${item?.reason}`)?.slice(0, 3)?.join(";\n") || errorResponse?.message;
}

export const checkPermission = (moduleName, permission, screenName = "any", field = "") => {
  return AbilityService.ability.can(`${moduleName}.action.${permission}`, `${moduleName}.screen.${screenName}`, field);
}

export const downloadFileToSystem = async (fileUrl, fileName) => {
  await fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      // Create a URL for the blob data
      var blobUrl = window.URL.createObjectURL(blob);

      // Create an anchor element
      var anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName;

      // Simulate a click on the anchor element
      anchor.click();

      // Clean up by revoking the blob URL and removing the anchor element
      window.URL.revokeObjectURL(blobUrl);
    });
}


export const downloadFileResponse = async (fileUrl) => {
  return await fetch(fileUrl)
    .then(response => response.blob())
    .then(blob => {
      // Create a URL for the blob data
      const blobUrl = window.URL.createObjectURL(blob);

      return blobUrl;
    });
}

export const extractFileNameFromURL = (url) => {
  // Split the URL by '/'
  const parts = url.split('/');

  // Get the last part of the URL which is the file name
  const fileNameWithTimestamp = parts[parts.length - 1];

  // Split the file name by underscore to separate timestamp and file name
  const fileNameParts = fileNameWithTimestamp.split('_');

  // Get the last part of the file name parts which is the actual file name
  const fileName = fileNameParts[fileNameParts.length - 1];

  return fileName;
}

export const refreshNewAddedItem = (s, row, item) => {
  // console.log(s.collectionView.currentEditItem);
  // console.log(Object.keys(s.collectionView.currentEditItem).length > 0);
  // console.log(row.index === 0);
  // console.log(s.newRowAtTop);
  // console.log(s.allowAddNew);
  if (s.collectionView.currentAddItem) {
    s.collectionView.commitNew();
  } else if (
    s.collectionView.currentEditItem &&
    Object.keys(s.collectionView.currentEditItem).length > 0 &&
    row.index === 0 &&
    s.newRowAtTop &&
    s.allowAddNew
  ) {
    let newItem = s.collectionView.addNew();
    for (let k in item) {
      newItem[k] = item[k];
    }
    row.dataItem = null;
    s.collectionView.cancelEdit();
    s.collectionView.commitNew();

    // var sel = s.selection;
    // var rowI = s.rows.length;
    // // select next cell if the entered data is valid
    // console.log(sel.row)
    // s.select(1, sel.col + 1);

  } else if (s.collectionView.currentEditItem) {
    s.collectionView.commitEdit();
  }
  return s;
};

export const findModifiedFields = (originalObject, newObject) => {
  return Object.keys(originalObject).filter(
    (key) => originalObject[key] !== newObject[key]
  );
}

export const formatValue = (inputValue) => {
  const number = parseFloat(inputValue);
  if (!isNaN(number) && isFinite(number)) {
    const formattedNumber = number.toFixed(2);
    return parseFloat(formattedNumber);
  }
  return inputValue;
}

export const formatIntValue = (inputValue) => {
  const number = parseInt(inputValue);
  if (!isNaN(number) && isFinite(number)) {
    const formattedNumber = number.toFixed(2);
    return parseInt(formattedNumber);
  }
  return inputValue;
}

export const getFileSize = (size) => {
  if (size < 1000) {
    return size + " B";
  } else if (size >= 1000 && size < 1000000) {
    const sizeInKB = size / 1000;
    return sizeInKB % 1 === 0 ? sizeInKB.toFixed(0) + " KB" : sizeInKB.toFixed(1) + " KB";
  } else {
    const sizeInMB = size / 1000000;
    return sizeInMB % 1 === 0 ? sizeInMB.toFixed(0) + " MB" : sizeInMB.toFixed(1) + " MB";
  }
}

export const getSignedUrl = async (file,entityType = "capex_requests") => {
  try {
    const payload = {
      fileName: file.name,
      entityType
    }
    const response = await fetchSignedUrl(payload)
    const { surl, purl } = response?.data?.data;
    if (surl) {
      await uploadDocumentToAzure(surl, file, file.type)
      return { status: true, purl }
    }
  } catch (error) {
    return { status: false, purl: "" }
  }
  return { status: false, purl: "" }
}

export const isAllSelectionEmptyAfterFilter = (selectedFilterItems) => {
  let isAllSelectionEmptyAfterFilter = true;
  for (let index = 0; index < Object.keys(selectedFilterItems?.current)?.length; index++) {
    if (selectedFilterItems?.current?.[index]?.length) {
      isAllSelectionEmptyAfterFilter = false;
    }
  }
  return isAllSelectionEmptyAfterFilter;
}

export const INVALID_CHARS = ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''];

export const referenceItems = ['reference1Name', 'reference2Name', 'reference3Name', 'reference4Name', 'reference5Name'];
export const referenceKeys = ['reference1', 'reference2', 'reference3', 'reference4', 'reference5'];

export const getUpdatedItemWithReference = (item) => {
  let count = 1;
  for (let i = referenceKeys.length; i > 1; i--) {
    const currentKey = referenceKeys[count];
    const prevKey = referenceKeys[count - 1];
    if (!!item[currentKey] && !item[prevKey]) {
      item[prevKey] = item[currentKey];
      item[currentKey] = null;
    }
    count++;
  }
  return item;
}

const brandNames = {
  "Provision 5Star": "Provision 5 Star",
  "Provision 4Star": "Provision 4 Star",
  "Provision 3Star": "Provision 3 Star",
}

export const mappedBrandNames = (name) => {
  return brandNames?.[name] || name;
}

const starTypeNames = {
  "5 STAR": "5 ⭐",
  "4 STAR": "4 ⭐",
  "3 STAR": "3 ⭐",
}

export const mappedStarTypeNames = (name) => {
  return starTypeNames?.[name] || name;
}