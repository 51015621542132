import { requestWithToken, simpleRequest } from "../apiConfigs/request";

export const loginUser = (userData) => {
  return simpleRequest({
    url: '/auth/login',
    method: 'POST',
    data: userData
  });
}

export const getBackendVersion = () => {
  return simpleRequest({
    url: '/ping?api_key=pr0visi0n',
    method: 'GET'
  });
}

export const loginSSOUser = (userData) => {
  return simpleRequest({
    url: '/auth/azure_login',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${userData?.accessToken}`
    },
  });
}

export const logOutUser = ()=> {
  return requestWithToken({
    url: '/auth/logout',
    method: "POST",
    data: {
      'refresh_token': localStorage.getItem("refreshToken")
    }
  })
}

export const fetchUserInfo = () => {
  return requestWithToken({
      url: '/users/me',
      method: "GET"
  })
}

export const callRefresh = () => {
  if (localStorage.getItem("refreshToken")) {
    return simpleRequest({
      url: '/auth/refresh',
      method: "POST",
      data: {
        'refreshToken': localStorage.getItem("refreshToken")
      }
    })
  } else {
    return new Promise(function (resolve, reject) {
      return reject("Refresh token not found")
    });
  }
}

export const forgotPassword = (data) => {
  return simpleRequest({
    url: '/auth/forgot_password',
    method: "POST",
    data
  })
}

export const changePassword = (data) => {
  return requestWithToken({
    url: '/auth/change_password',
    method: "POST",
    data
  })
}

const AuthService = {
  callRefresh,
  logOutUser,
  loginUser,
  fetchUserInfo,
  forgotPassword,
  changePassword,
}
export default AuthService;