import { requestWithToken } from "../../apiConfigs/request";

export const fetchDivisions = (query="") => {
  return requestWithToken({
    url: `/division${query}`,
    method: 'GET',
  });
}

export const createDivision = (data) => {
  return requestWithToken({
    url: `/division`,
    method: 'POST',
    data
  });
}

export const updateDivision = (divisionId, data) => {
  return requestWithToken({
    url: `/division/${divisionId}`,
    method: 'PUT',
    data
  });
}

export const deleteDivision = (divisionId) => {
  return requestWithToken({
    url: `/division/${divisionId}`,
    method: 'DELETE'
  });
}
