import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useMsal } from '@azure/msal-react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Badge, Button, Dropdown, Menu, message, Popover, Tooltip, Typography } from 'antd';
import { BellFilled, KeyOutlined, LogoutOutlined, UserOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { MenuItems } from './menuItems';
import { signOutUser } from '../../store/actions/auth';
import cookieService from '../../services/cookie.service';
import CookieList from '../../constants/cookieList';
import UnsavedModal from '../../molecules/Modal/UnsavedModal';
import NotificationPopUp from '../../molecules/NotificationBoard/notificationPopup';
import * as actions from '../../store/actions/notifications';
import * as authActions from '../../store/actions/auth';
import { markAsReadNotifications } from '../../services/notification.service';
import { getErrorMessage } from '../../utils/globalFunctions';

const { SubMenu } = Menu;
const { Title } = Typography;

const HeaderMenu = (props) => {
  const { instance, accounts  } = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentKey, getCurrentKey] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [isNotificationClicked, setIsNotificationClicked] = useState(false);
  const [defaultKey, setdefaultKey] = useState(null);

  const { user, notifications, userAuthenticated } = useSelector((state) => {
    return {
      user: state?.auth?.user?.user,
      notifications: state?.notifications || [],
      userAuthenticated: state?.auth?.accessToken
    }
  });

  useEffect(() => {
    const currentPath = location.pathname;
    if (!currentKey) {
      getCurrentKey(currentPath?.split('/')?.[2]);
    }
    if (userAuthenticated && !notifications?.notifications?.length && notifications?.isNotificationLoading) {
      // getNotifications();
      // dispatch(authActions.getUserInfo());
    }
  }, []);

  const getNotifications = () => {
    dispatch(actions.getNotifications());
  }

  const ProfileSettings = (
    <Menu>
      <Menu.Item key="8" disabled={true} id="user_name">
        <UserOutlined /> {`${user?.firstName || ""}${user?.role?.roleName ? ` (${user?.role?.roleName}) ` : ''}`}
      </Menu.Item>
      <Menu.Item
        key='changePassword'
        onClick={() => changePage('/change-password')}
        disabled={user?.isInternalUser}
        id="changePassword"
      >
        <KeyOutlined /> Change Password
      </Menu.Item>

      {
        user?.role?.roleSlug === 'admin' ?
          <Menu.Item
            key='version'
            onClick={() => changePage('/versions')}
            id="version"
          >
            <InfoCircleOutlined /> About
          </Menu.Item>
        : null
      }


      <Menu.Item
        key="9"
        onClick={() => changePage("/login")}
        id="logout"
      >
        {" "}
        <LogoutOutlined /> Log Out
      </Menu.Item>
    </Menu>
  );

  const NotificationContent = (
    <div
      className="notify-scroll-height dd-custom-scroller noti-popover-width"
    >
      <NotificationPopUp
        notifications={notifications}
        getNotifications={getNotifications}
        setOpenNotifications={setOpenNotifications}
        userRole={user?.role?.roleSlug}
      />
    </div>
  );

  const logoutUser = (key) => {
    props?.setloading(true);
    navigate(key);
    if (!!accounts?.length) {
      instance.logoutPopup();
    }
    dispatch(signOutUser()).then(() => {
      if (props?.setloading) {
        props?.setloading(false);
      }
      message.success("Logged Out Successfully.");
    })
  }

  const clearCookies = () => {
    setIsModalVisible(false);
    cookieService.remove(CookieList.unsavedValue);
    cookieService.set(CookieList.unsavedValue, false);
    cookieService.remove(CookieList.createRequest);
    cookieService.set(CookieList.createRequest, false);
  }

  const handleOk = () => {
    if (!!isNotificationClicked) {
      setIsNotificationClicked(false);
      setOpenNotifications(true);
    } else if (defaultKey === "/login") {
      logoutUser(defaultKey);
    } else if (defaultKey !== null && defaultKey !== "/login") {
      navigate(defaultKey);
    } else if (defaultKey === "/") {
      navigate('/');
    }
    clearCookies();
  };

  //To redirect to operation data, vendor data, master data
  const changePage = (key) => {
    setdefaultKey(key);
    let value = cookieService.get(CookieList.unsavedValue);
    let value2 = cookieService.get(CookieList.createRequest);
    if (value === true || value === "true") {
      setIsModalVisible(true);
    } else if ((value2 === "true" || value2 === true)) {
      setIsModalVisible(true);
    }
    else {
      if (key === '/login') {
        logoutUser(key);
      } else {
        props?.setProject(null)
        navigate(key);
      }
    }
  };

  const handleMarkNotificationRead = async () => {
    await markAsReadNotifications().then((res) => {
      message.success(res?.data?.message);
      getNotifications();
    }).catch((error) => {
      const errorMessage = getErrorMessage(error);
      message.error(error?.message || errorMessage);
    });
  }

  const headerText = (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Title level={5} style={{ margin: 0 }} className="notification-header-title">Notifications</Title>
      <Button type="link" style={{ margin: 0 }} onClick={handleMarkNotificationRead}>Mark all as read</Button>
    </div>
  );

  return (
    <>
      {
        user?.role?.roleSlug ?
          <div>

            <Menu
              onClick={(e) => getCurrentKey(e.key)}
              selectedKeys={[currentKey]}
              theme="light"
              mode="horizontal"
              className="dash-horizontal-menu"
              inlineCollapsed={false}
              key={user?.role?.roleSlug}
              id='dash_horizontal_menu'
            >

              {
                (user?.role?.roleSlug === 'admin'
                  || user?.role?.roleSlug === 'manager'
                  || user?.role?.roleSlug === 'coordinator') ?

                  <>
                    <SubMenu
                      key="1"
                      title="Vendor Data"
                      disabled={false}
                    >
                      {
                        MenuItems.venderData.map(item => {
                          return (
                            <Menu.Item
                              key={item.key}
                              onClick={() => changePage(item.path)}
                              disabled={item?.isDisabled}
                              id={item.key}
                            >
                              {item.name}
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                    <SubMenu
                      key="2"
                      title="Master Data"
                    >
                      {
                        MenuItems.masterData?.filter(item => !item?.isRemoved).map(item => {
                          return (
                            <Menu.Item
                              key={item.key}
                              onClick={() => changePage(item.path)}
                              disabled={item?.isDisabled}
                              id={item.key}
                            >
                              {item.name}
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                    <SubMenu
                      key="3"
                      title="Operations Data"
                      id="operations_data"
                    >
                      {
                        MenuItems.operationsData.map(item => {
                          return (
                            <Menu.Item
                              key={item.key}
                              onClick={() => changePage(item.path)}
                              disabled={item?.isDisabled}
                              id={item.key}
                            >
                              {item.name}
                            </Menu.Item>
                          )
                        })
                      }
                    </SubMenu>
                  </>
                  : null
              }
              {user?.role?.roleSlug === "vendor" ? (
                <Menu.Item
                  key="VendorDashboard"
                  onClick={() => changePage("/VendorDashboard")}
                  className="top-nav-btn"
                  disabled={false}
                  id="VendorDashboard"
                >
                  Vendor Data
                </Menu.Item>
              ) : null}
              <Menu.Item key="6">
                <Popover
                  content={NotificationContent}
                  placement="bottomRight"
                  title={headerText}
                  trigger="click"
                  open={openNotifications}
                  onOpenChange={() => {
                    let value = cookieService.get(CookieList.unsavedValue);
                    if (value === true || value === "true") {
                      setIsModalVisible(true);
                      setIsNotificationClicked(true);
                    } else {
                      setOpenNotifications(!openNotifications)
                      // if (!!openNotifications) {
                      //   cookieService.set(CookieList.unsavedValue, true);
                      // }
                    }
                  }}
                >
                  {notifications?.notifications?.length !== 0 ? (
                    <Tooltip
                      placement="bottom"
                      title={"You have " + notifications?.notifications?.length + " notifications"}
                    >
                      <Badge
                        size="small"
                        count={notifications?.notifications?.length}
                        overflowCount={999}
                      >
                        <BellFilled className="active-notification" onClick={() => {
                          let value = cookieService.get(CookieList.unsavedValue);
                          console.log(value)
                          if (value === true || value === "true") {
                            setIsModalVisible(true);
                            setIsNotificationClicked(true);
                          } else {
                            setOpenNotifications(!openNotifications)
                          }
                        }} id="header_notifications" />
                      </Badge>
                    </Tooltip>
                  ) : (
                    <Tooltip placement="bottom" title="No notifications">
                      <BellFilled className="inactive-notification" />
                    </Tooltip>
                  )}
                </Popover>
              </Menu.Item>
              <Menu.Item key="7" className="user-menu-item" id="header_avatar_menu">
                <Dropdown overlay={ProfileSettings}>
                  {
                    user?.logoUrl ?
                      <Avatar src={`${process.env.REACT_APP_FILE_BASE_URL}/${user?.logoUrl}`} id="header_avatar" />
                      :
                      <Avatar icon={<UserOutlined />} id="header_avatar" />
                  }
                </Dropdown>
              </Menu.Item>
            </Menu>
          </div>
          : null

      }
      <UnsavedModal
        className="header-unsaved"
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancelUnsave={() => {
          setIsNotificationClicked(false)
          setIsModalVisible(false)
        }}
      />
    </>
  );
};

export default HeaderMenu;
