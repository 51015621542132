export const NOTIFICATION_TYPES = {
  USER_CREATE: "user.create",
  CLIENT_PROJECT_PQF_SEND: "client.project.pqf.send",
  PROJECT_PQF_SEND: "project.pqf.send",
  PROJECT_ENQUIRY_SEND: "project.enquiry.send",
  PROJECT_ENQUIRY_SUBMIT: "project.enquiry.submit",
  VENDOR_ENQUIRY_SUBMIT: "vendor.enquiry.submit",
  VENDOR_PQQ_SEND: "vendor.pqq.send",
  VENDOR_PQQ_SUBMIT: "vendor.pqq.submit",
  VENDOR_PQQ_REOPEN: "vendor.pqq.reopen",
  VENDOR_ALLOWANCE_RESEND: "vendor.allowance.resend",
  BOQ_CLIENT_APPROVAL: "boq.client.approval",
  BOQ_CLIENT_APPROVAL_SEND: "boq.client.approval.send",
  BOQ_CREATE: "boq.create",
  CLIENT_PQF_SUBMITTED: "client.pqf.submitted",
  BOQ_CLIENT_APPROVED: "boq.client.approved",
  BOQ_CLIENT_REJECTED: "boq.client.rejected",
  VENDOR_RFP_RECALL: "vendor.rfp.recall",
  VENDOR_RFP_ISSUE: "vendor.rfp.issue",
  VENDOR_RFP_SUBMIT: "vendor.rfp.submit",
  BUDGET_PROVISION_APPROVE: "budget.provision.approve",
  BUDGET_CLIENT_REJECT: "budget.client.reject",
  BUDGET_CLIENT_APPROVE: "budget.client.approve",
  BUDGET_CLIENT_SEND: "budget.client.send",
  SEND_PRODUCT_CATALOG_FOR_APPROVAL: "product.catalog.send",
  SEND_PRODUCT_DESTINATION_FOR_APPROVAL: "product.destination.send",
  SEND_BOQ_TEMPLATE_FOR_APPROVAL: "boq.template.send",
  CAPEX_COMMENT_SEND: "capex.comment.send",
  CAPEX_REQUEST_SUBMIT: "capex.request.submit",
  CAPEX_REQUEST_UPDATE: "capex.request.update",
  SEND_OSNE_BOQ_BUDGET: "boq.budget.send",
  SEND_OSNE_BOQ_BUDGET_CLIENT: "boq.budget.client.send",
  SUBMIT_OSNE_CLIENT_BUDGET: "boq.budget.client.submit",
  VENDOR_SELECTION_APPROVED: "vendor.selection.approved",
  BOQ_MANAGER_APPROVED: "boq.manager.approved",
  BOQ_MANAGER_REJECTED: "boq.manager.rejected",
}

export const PROPERTY_INFORMATION_FFNE_DOCUMENT_TYPES = [
  {
    "documentType": "All Drawings by Area",
    "documentTypeSlug": "drawings_by_area"
  },
  {
    "documentType": "Detail Drawing",
    "documentTypeSlug": "detail_drawing"
  },
  {
    "documentType": "Drawing Register",
    "documentTypeSlug": "drawing_register"
  },
  {
    "documentType": "Elevations",
    "documentTypeSlug": "elevations"
  },
  {
    "documentType": "FF&E Specifications",
    "documentTypeSlug": "ffe_specifications"
  },
  {
    "documentType": "Finish Specifications",
    "documentTypeSlug": "finish_specifications"
  },
  {
    "documentType": "Furniture Layout Plan",
    "documentTypeSlug": "furniture_layout_plan"
  },
  {
    "documentType": "Renderings",
    "documentTypeSlug": "renderings"
  }
]

export const PROPERTY_INFORMATION_FFNE_DOCUMENT_TYPE_MAPPING = {
  drawings_by_area: "All Drawings by Area",
  detail_drawing: "Detail Drawing",
  drawing_register: "Drawing Register",
  elevations: "Elevations",
  ffe_specifications: "FF&E Specifications",
  finish_specifications: "Finish Specifications",
  furniture_layout_plan: "Furniture Layout Plan",
  renderings: "Renderings",
  fnb_restaurants: "OS&E",
  banquets: "OS&E",
  recreational_areas: "OS&E",
  others: "OS&E"
}