import * as actionTypes from "./actionTypes";
import { fetchBusinessTypes } from "../../../services/businessType.service";
import { fetchCurrency } from "../../../services/currencyAndTaxtype.service";
import { fetchClients } from "../../../services/operationServices/clients.service";
import { fetchOperators } from "../../../services/operationServices/operators.service";
import { fetchDivisions } from "../../../services/operationServices/division.service";
import { fetchDesigners } from "../../../services/operationServices/designer.service";
import { fetchAllProjects } from "../../../services/operationServices/projects.service";

export const getBusinessTypes = () => {
  return dispatch => {
    return fetchBusinessTypes().then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_BUSINESS_TYPE_DATA, data: res?.data?.data });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_BUSINESS_TYPE_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getCurrency = (query="") => {
  return dispatch => {
    return fetchCurrency(query).then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_CURRENCY_DATA, data: res?.data?.data?.currency });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_CURRENCY_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getClientData = (query="") => {
  return dispatch => {
    return fetchClients(query).then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_CLIENT_DATA, data: res?.data?.data?.clients });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_CLIENT_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getDivisionData = (query="") => {
  return dispatch => {
    return fetchDivisions(query).then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_DIVISION_DATA, data: res?.data?.data?.divisions });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_DIVISION_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getOperatorData = (query="") => {
  return dispatch => {
    return fetchOperators(query).then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_OPERATOR_DATA, data: res?.data?.data?.operators });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_OPERATOR_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getDesignerData = (query="") => {
  return dispatch => {
    return fetchDesigners(query).then(res => {
      dispatch({ type: actionTypes.SET_PROJECT_DESIGNER_DATA, data: res?.data?.data?.designers });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECT_DESIGNER_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const getProjects = () => {
  return dispatch => {
    return fetchAllProjects().then(res => {
      dispatch({ type: actionTypes.SET_PROJECTS_DATA, data: res?.data?.data?.projects });
      return res;
    }).catch(err => {
      console.log(err);
      dispatch({ type: actionTypes.SET_PROJECTS_DATA, data: [], isLoading: false });
      return err;
    });
  }
}

export const resetProjectData = () => {
  return dispatch => {
    dispatch({ type: actionTypes.SET_PROJECT_BUSINESS_TYPE_DATA, data: [], isLoading: true  });
    dispatch({ type: actionTypes.SET_PROJECT_CURRENCY_DATA, data: [], isLoading: true });
    dispatch({ type: actionTypes.SET_PROJECT_CLIENT_DATA, data: [], isLoading: true });
    dispatch({ type: actionTypes.SET_PROJECT_DIVISION_DATA, data: [], isLoading: true });
    dispatch({ type: actionTypes.SET_PROJECT_OPERATOR_DATA, data: [], isLoading: true });
    dispatch({ type: actionTypes.SET_PROJECT_DESIGNER_DATA, data: [], isLoading: true });
    return {}
  }
}