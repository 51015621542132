import jwt from 'jwt-decode';
import { message } from "antd";
import AbilityService from '../../../services/ability.service';
import { fetchUserInfo, loginSSOUser, loginUser, logOutUser } from '../../../services/auth.service';
import * as actionTypes from './actionTypes';
import { _setToken, setupRefreshTimer } from '../../../utils/authFunctions';
import zipy from 'zipyai';

export const setAuthData = (data) => {
  return {
    type: actionTypes.SET_AUTH_DATA,
    data,
  }
}

export const signInUser = (userData) => {
  return dispatch => {
    return loginUser(userData).then(res => {
      _setToken(res?.data?.data?.tokens);
      setupRefreshTimer();
      dispatch(setAuthData(res?.data?.data));
      const token = jwt(res?.data?.data?.tokens?.accessToken);
      localStorage.setItem('currentReleaseVersion', token?.user?.version);
      return res;
    }).catch(err => {
      console.log(err);
      return err;
    });
  }
}

export const signInSSOUser = (userData) => {
  return dispatch => {
    return loginSSOUser(userData).then(res => {
      _setToken(res?.data?.data?.tokens);
      setupRefreshTimer();
      dispatch(setAuthData(res?.data?.data));
      return res;
    }).catch(err => {
      console.log(err);
      return err;
    });
  }
}

export const signOutUser = () => {
  return dispatch => {
    return logOutUser().then(res => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch({type: "CLEAR_STORE"});
      return res
    }).catch(err => {
      console.log(err);
      return err;
    });
  }
}

export const getUserInfo = () => {
  return dispatch => {
    return fetchUserInfo().then(res => {
      if (!!Number(process.env.REACT_APP_QNA_RECORD) && process.env.REACT_APP_ZIPY_ID) {
        zipy.identify(res?.data?.data?.user?.email, {
          firstName: res?.data?.data?.user?.firstName,
          lastName: res?.data?.data?.user?.lastName,
          email: res?.data?.data?.user?.email,
        });
      }
      AbilityService.addGlobalPermissions(res?.data?.data?.permissions, []);
      dispatch({ type: actionTypes.SET_USER_INFO, data: res?.data?.data });
      return res;
    }).catch(err => {
      console.log(err);
      const errorResponse = err?.response ? JSON.parse(err?.response) : {}
      if (errorResponse?.statusCode === 401) {
        message.error(errorResponse?.message);
        dispatch(signOutUser());
      }
      return err;
    });
  }
}
