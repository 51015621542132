import React from "react";
import {
  Spin
} from "antd";
import CustomSpin from "../CustomLoader";

const Loader = () => (
  <CustomSpin style={{height:"100%",width:"100%"}} imgStyle={{margin: '-18px', paddingLeft: '10px'}} spinning={true} tip="Loading..."><div></div></CustomSpin>
);

export default Loader;